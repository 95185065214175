import { ThemeButton } from "./components/ThemeButton";
import { absoluteCenter } from "./styles";
import { publisher } from "../api/helper";

export const Setting = () => {
  return (
    <div style={{ ...absoluteCenter, top: "38%" }}>
      <ThemeButton
        onClick={async () => {
          await publisher();
        }}
        style={{
          width: "500px",
          height: "70px",
        }}
        text={"用紙をセット"}
      />
      <ThemeButton
        style={{
          width: "500px",
          height: "70px",
          marginTop: "30px",
        }}
        text={"戻る"}
        onClick={function (): void {
          window.location.href = "/";
        }}
      />
    </div>
  );
};
