import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./App";
import { Setting } from "./view/Setting";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/setting" element={<Setting />} />

        {/* Not Found */}

        <Route path="*" element={<div>404 NotFound</div>} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
