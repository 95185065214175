import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Timer, convertToTitleCountFormat, publisher } from "../api/helper";
import { OrderData, OrderListProps } from "../api/type";

interface Props {
  order: OrderData;
}

export const Print = (props: Props) => {
  const [orderList, setOrderList] = useState<OrderListProps[]>();

  useEffect(() => {
    const menuTitleList = props.order.menu.map((menu) => menu.title);
    setOrderList(convertToTitleCountFormat(menuTitleList));
    (async () => {
      await Timer(15000 * props.order.menu.length);
      await publisher("failed");
    })();
  }, [props.order]);
  return (
    <div
      style={{
        display: "grid",
        gridTemplate: `
        " title  title " 100px
        " name  illust " 100px
        " menu  illust " 400px
        /50% 50%`,
      }}
    >
      <CustomGridArea
        className="japanese_R"
        gridArea="title"
        fontSize="45px"
        textAlign="center"
      >
        発券中です
      </CustomGridArea>
      <CustomGridArea gridArea="name" fontSize="30px">
        <TextWrapper className="japanese_R">
          {props.order.user.mailAddress}
        </TextWrapper>
      </CustomGridArea>

      <CustomGridArea
        gridArea={"menu"}
        fontSize={"30px"}
        textAlign={"left"}
        style={{
          marginLeft: "0px",
        }}
      >
        <TextWrapper>
          {orderList?.map((menu) => {
            return (
              <div
                key={menu.title}
                className="japanese_R"
                style={{
                  color: "#707070",
                }}
              >
                {menu.title} ×{menu.count}
              </div>
            );
          })}
        </TextWrapper>
      </CustomGridArea>
      <CustomGridArea gridArea={"illust"}>
        <img
          style={{
            width: "500px",
          }}
          src="/images/loadingPrinter.gif"
          alt="logo"
        />
      </CustomGridArea>
    </div>
  );
};

const CustomGridArea = styled.div<{
  gridArea: string;
  fontSize?: string;
  textAlign?: string;
}>`
  text-align: ${(props) => props.textAlign};
  color: #006c9b;
  grid-area: ${(props) => props.gridArea};
  font-size: ${(props) => props.fontSize};
`;

const TextWrapper = styled.div`
  width: 350px;
  margin: 30px 0 0 150px;
  color: #006c9b;
`;
