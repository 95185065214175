import { Button, Grid } from "@mui/material";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../api/firebase";
import { MenuData } from "../api/type";
import { ConfirmDialog } from "./components/ConfirmDialog";
import { LoadingAnimation } from "./components/LoadingAnimation";

interface Props {
  onNext: () => void;
}
export const Admin = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [menuData, setMenuData] = useState<any[]>([]);
  const [allDelete, setAllDelete] = useState(false);
  const [allSales, setAllSales] = useState(false);
  const categoryNameStyle = {
    textAlign: "center" as "center",
    margin: "3vw 0 1vw 0",
    color: "#707070",
  };
  useEffect(() => {
    window.document.title = "RunTicket for Restaurant";
    (async () => {
      const q = query(collection(db, "menu"), where("isStatus", "==", true));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setMenuData((menuData) => [...menuData, doc.data()]);
      });
      setIsLoading(true);
    })();
  }, []);

  const AllDataUpdate = (mode: boolean) => {
    const promises = menuData.map(async (menu) => {
      await updateDoc(doc(db, "menu", menu.id), {
        isSale: mode,
      });
    });
    Promise.all(promises).then(() => {
      props.onNext();
      window.location.reload();
    });
  };
  return (
    <div>
      {isLoading ? (
        <div>
          <div style={{ textAlign: "center", margin: "2vw 2vw" }}>
            <Button
              variant="outlined"
              color="error"
              style={{ marginRight: "2vw", padding: "2vw 1.5vw" }}
              onClick={() => {
                setAllDelete(true);
              }}
            >
              <div>全メニュー売り切れにする</div>
            </Button>
            <Button
              variant="outlined"
              style={{ padding: "2vw 1.5vw" }}
              color="info"
              onClick={() => {
                setAllSales(true);
              }}
            >
              <div>全メニュー販売中にする</div>
            </Button>
          </div>
          {menuData.length !== 0 && <h2 style={categoryNameStyle}>メイン</h2>}
          {menuData
            .filter((e) => e.category === "メイン")
            .map((id, index) => {
              return (
                <ListContent
                  menuData={id}
                  key={index}
                  onNext={function (): void {
                    props.onNext();
                  }}
                />
              );
            })}
          {menuData.length !== 0 && <h2 style={categoryNameStyle}>ドリンク</h2>}
          {menuData
            .filter((e) => e.category === "ドリンク")
            .map((id, index) => {
              return (
                <ListContent
                  menuData={id}
                  key={index}
                  onNext={function (): void {
                    props.onNext();
                  }}
                />
              );
            })}
          {menuData.length !== 0 && (
            <h2 style={categoryNameStyle}>トッピング</h2>
          )}
          {menuData
            .filter((e) => e.category === "トッピング")
            .map((id, index) => {
              return (
                <ListContent
                  menuData={id}
                  key={index}
                  onNext={function (): void {
                    props.onNext();
                  }}
                />
              );
            })}
        </div>
      ) : (
        <LoadingAnimation type={"leapfrog"} top="20%" />
      )}

      {menuData.length === 0 && isLoading && (
        <div
          className="japanese_L"
          style={{
            textAlign: "center" as "center",
            margin: "10vh 0",
            color: "#C42A00",
          }}
        >
          現在、メニューデータがありません
        </div>
      )}
      <div style={{ textAlign: "center", marginTop: "3vw" }}>
        <Button
          fullWidth
          onClick={() => {
            props.onNext();
          }}
          style={{ height: "7vw" }}
          variant="contained"
        >
          <div className="japanese_L" style={{ fontSize: "30px" }}>
            トップへ戻る
          </div>
        </Button>
      </div>
      <ConfirmDialog
        title={"全メニュー売り切れ"}
        content={"全てのメニューを売り切れにします。本当によろしいですか？"}
        open={allDelete}
        buttonText={"はい"}
        isNoButton={true}
        onConfirm={function (): void {
          AllDataUpdate(false);
          setAllDelete(false);
        }}
        onClose={function (): void {
          setAllDelete(false);
        }}
      />
      <ConfirmDialog
        title={"全メニュー販売"}
        content={"全てのメニューを販売中にします。本当によろしいですか？"}
        open={allSales}
        buttonText={"はい"}
        isNoButton={true}
        onConfirm={function (): void {
          AllDataUpdate(true);
          setAllSales(false);
        }}
        onClose={function (): void {
          setAllSales(false);
        }}
      />
    </div>
  );
};

interface ListContentProps {
  menuData: MenuData;
  onNext: () => void;
}
const ListContent = (props: ListContentProps) => {
  const [open, setOpen] = useState(false);
  const isSale: boolean = props.menuData.quantity > 0;
  const DataUpdata = async (menu: MenuData) => {
    await updateDoc(doc(db, "menu", menu.id), {
      quantity: !isSale ? 0 : 10,
    });
    window.location.reload();
  };
  return (
    <div>
      <div
        className="japanese_L"
        style={{
          margin: "2vh auto",
          boxShadow: "0px 3px 6px #00000029",
          padding: "2vh 0",
          textAlign: "center" as "center",
          width: "90vw",
          fontSize: "30px",
          borderRadius: "10px",
          color: "#006C9B",
          backgroundColor: isSale ? "white" : "rgba(0,0,0,0.5)",
        }}
      >
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid item xs={9}>
            <div
              style={{
                textAlign: "left" as "left",
                color: isSale ? "#006C9B" : "#F87366",
              }}
            >
              {props.menuData.title} ({isSale ? "販売中" : "売り切れ"})
            </div>
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={() => {
                setOpen(true);
              }}
              variant="contained"
              style={{
                height: "40px",
                backgroundColor: !isSale ? "#006C9B" : "#F87366",
              }}
            >
              <div className="japanese_B" style={{ fontSize: "15px" }}>
                {isSale ? "売り切れ" : "販売する"}
              </div>
            </Button>
          </Grid>
        </Grid>
        <ConfirmDialog
          title={props.menuData.title}
          content={
            props.menuData.title +
            "を" +
            (isSale ? "売り切れにしますか？" : "販売しますか？")
          }
          open={open}
          buttonText={"はい"}
          isNoButton={true}
          onConfirm={function (): void {
            DataUpdata(props.menuData);
            props.onNext();
            setOpen(false);
          }}
          onClose={function (): void {
            setOpen(false);
          }}
        />
      </div>
    </div>
  );
};
