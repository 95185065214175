import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface ConfirmDialogProps {
  title: string;
  content: string;
  open: boolean;
  buttonText: string;
  isNoButton: boolean;
  onConfirm: () => void;
  onClose: () => void;
}
export const ConfirmDialog = (props: ConfirmDialogProps) => {
  return (
    <div>
      <Dialog
        fullWidth
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>いいえ</Button>
          <Button variant="contained" onClick={props.onConfirm}>
            はい
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
