import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { NewTimer, Timer, publisher } from "../api/helper";
import { Mode } from "../api/type";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { ThemeButton } from "./components/ThemeButton";
import { audio, se } from "../App";
import { BlinkingIcon } from "./Home";
import TouchAppIcon from "@mui/icons-material/TouchApp";

interface Props {
  mode: Mode;
}
export const Complete = ({ mode }: Props) => {
  const [countTime, setCountTime] = useState(3);
  const [isStart, setIsStart] = useState(false);
  const redirectTimer = useRef(new NewTimer(3000, setCountTime, "/"));
  useEffect(() => {
    mode === Mode.ticketingFailed && audio[se.failedVoice].play();
    (async () => {
      setIsStart(true);
      if (mode === Mode.ticketingFailed) return;
      audio[se.submit].play();
      await Timer(500);
      mode === Mode.complete && audio[se.thanksVoice].play();
      redirectTimer.current.afterToPage();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{
        minHeight: "100%",
      }}
      onClick={() => {
        redirectTimer.current.clearTimer();
        mode === Mode.complete && window.location.reload();
      }}
    >
      {(mode === Mode.notFound || mode === Mode.ticketingFailed) && (
        <IconWrapper>
          <ReportProblemIcon style={{ fontSize: "220px" }} />
        </IconWrapper>
      )}
      <TextWrapper
        className="japanese_L"
        top={30}
        fontSize={45}
        color={mode === Mode.complete ? "#006c9b" : "#D30B0B"}
      >
        {mode === Mode.complete && <p>ご注文ありがとうございました</p>}
        {mode === Mode.notFound && "注文情報が見つかりませんでした"}
        {mode === Mode.ticketingFailed && "ERROR: 発券に失敗しました"}
      </TextWrapper>
      {isStart && mode !== Mode.ticketingFailed && (
        <TextWrapper
          className="japanese_L"
          top={56}
          fontSize={25}
          color={"#707070"}
        >
          <div>
            <BlinkingIcon>
              <TouchAppIcon style={{ fontSize: "60px" }} />
            </BlinkingIcon>
          </div>
          {countTime}秒後に自動的にトップへ戻ります
        </TextWrapper>
      )}
      {isStart && mode === Mode.ticketingFailed && (
        <div>
          <TextWrapper
            className="japanese_L"
            top={56}
            fontSize={25}
            color={"#707070"}
          >
            係員を呼んでください
          </TextWrapper>
          <ThemeButton
            onClick={async () => {
              await publisher();
            }}
            style={{
              position: "absolute",
              bottom: "20%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            text={"用紙をセット"}
          />
          <ThemeButton
            onClick={() => {
              window.location.href = "/";
            }}
            style={{
              position: "absolute",
              bottom: "7%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            text={"HOMEに戻る"}
          />
        </div>
      )}
    </div>
  );
};

const TextWrapper = styled.div<{
  top: number;
  fontSize: number;
  color: string;
}>`
  color: ${(props) => props.color};
  position: absolute;
  font-size: ${(props) => props.fontSize}px;
  top: ${(props) => props.top}%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px 0 0px 0;
  color: #d30b0b;
`;
