import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "./firebase";
import {
  OrderData,
  OrderListProps,
  TicketSubscribeTypes,
  subscriberStatus,
} from "./type";

export const apiURL = "https://runticket.local/";
export const audioURL = apiURL + "se?audioName=";

export function isValidRandomString(str: string) {
  const pattern = new RegExp("^[a-zA-Z0-9]{16}$");
  return pattern.test(str);
}

export const GetSpecificData = async (docId: string, collectionId: string) => {
  const docRef = doc(db, docId, collectionId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const status = docSnap.data().isStatus;
    return status === "ordered" || status === "cooked"
      ? (docSnap.data() as OrderData)
      : null;
  } else {
    return null;
  }
};

export const convertToTitleCountFormat = (dataArray: Array<string>) => {
  const uniqueArray = dataArray.filter((x, i, self) => self.indexOf(x) === i);
  return uniqueArray.map((title) => {
    const count = dataArray.filter((x) => x === title).length;
    return { title, count } as OrderListProps;
  });
};

export class NewTimer {
  private countTimer: any;

  constructor(
    private readonly msSecWaitTime: number,
    private readonly setTimeNumber: (time: number) => void,
    private readonly toUrl: string
  ) {
    this.msSecWaitTime = msSecWaitTime;
    this.setTimeNumber = setTimeNumber;
    this.toUrl = toUrl;
    this.countTimer = null;
  }

  startTimer = () => {
    if (this.countTimer !== null) {
      this.clearTimer();
    }
    return new Promise((resolve) => {
      this.countTimer = setTimeout(() => {
        resolve("");
      }, this.msSecWaitTime);
    });
  };

  clearTimer = () => {
    clearTimeout(this.countTimer);
    this.countTimer = null;
  };

  afterToPage = async () => {
    let time = this.msSecWaitTime / 1000;
    setInterval(() => {
      time--;
      this.setTimeNumber(time > 0 ? time : 1);
    }, 1000);
    await this.startTimer();
    console.log("after");
    window.location.href = this.toUrl;
  };
}

export const Timer = (msSecWaitTime: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("");
    }, msSecWaitTime);
  });
};
export const publisher = async (
  id: string = "cut",
  title: string[] = [],
  status: subscriberStatus = "failed"
) => {
  const time = new Date();
  const payload: TicketSubscribeTypes = {
    id: id,
    title: title.toString(),
    date: time.toLocaleDateString(),
    time: time.toLocaleTimeString(),
    status: status,
  };
  await setDoc(doc(db, "system", "subscriber"), payload);
};
