import { Button } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {
  text: string;
  onClick?: () => void;
  style: React.CSSProperties;
  to?: string;
}
export const ThemeButton = (props: Props) => {
  return (
    <div>
      {props.onClick && (
        <Button
          variant="outlined"
          style={{
            ...baseStyle,
            ...props.style,
          }}
          onClick={() => {
            props.onClick && props.onClick();
          }}
        >
          {props.text}
        </Button>
      )}
      {props.to && (
        <Button
          variant="outlined"
          style={{
            ...baseStyle,
            ...props.style,
          }}
          component={Link}
          to={props.to}
        >
          {props.text}
        </Button>
      )}
    </div>
  );
};

const baseStyle: React.CSSProperties = {
  padding: "7px 120px",
  fontSize: "30px",
  color: "#006c9b",
  borderColor: "#006c9b",
  borderRadius: "10px",
  boxShadow: "none",
};
