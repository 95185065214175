import React, { useRef, useState } from "react";
import "./view/styles/App.css";
import QRReader, { QRCode } from "./view/QRReader";
import {
  adminId,
  ErrorMessages,
  Mode,
  OrderData,
  TicketSubscribeTypes,
} from "./api/type";
import { Header } from "./view/Header";
import { Home } from "./view/Home";
import { GetSpecificData, isValidRandomString, publisher } from "./api/helper";
import { Print } from "./view/Print";
import { Fetch } from "./view/Fetch";
import { Complete } from "./view/Complete";
import { Admin } from "./view/Admin";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "./api/firebase";

export enum se {
  qr,
  submit,
  failed,
  thanksVoice,
  failedVoice,
}
export const audio = [
  new Audio("/se/qr.wav"),
  new Audio("/se/submit.wav"),
  new Audio("/se/failed.wav"),
  new Audio("/se/thankyou_voice.wav"),
  new Audio("/se/ticket_failed_voice.wav"),
];

audio.forEach((e) => {
  e.muted = true;
});
const prepareSound = () => {
  audio.forEach(async (e) => {
    e.play();
    e.pause();
  });
  audio.forEach((e) => {
    e.muted = false;
  });
};

function App() {
  const [mode, setMode] = useState<Mode>(Mode.home);
  const [errMessage, setErrMessage] = useState<ErrorMessages | null>();
  const [order, setOrder] = useState<OrderData | null>(null);
  const [pause, setPause] = useState<boolean>(false);
  const isFirstAccess = useRef(true);
  const isSecondAccess = useRef(false);

  const onRecognizeCode = async (e: QRCode) => {
    if (!isFirstAccess.current) return;
    isFirstAccess.current = false;
    if (e.data === adminId) {
      setMode(Mode.admin);
      isFirstAccess.current = true;
      return;
    }
    if (!isValidRandomString(e.data)) {
      setErrMessage(ErrorMessages.notType);
      isFirstAccess.current = true;
      return;
    }
    setPause(true);
    setErrMessage(null);

    onSnapshot(doc(db, "system", "subscriber"), (doc) => {
      const data = doc.data() as TicketSubscribeTypes;
      if (isSecondAccess.current) {
        data.status === "scanned" && setMode(Mode.print);
        data.status === "success" && setMode(Mode.complete);
        data.status === "failed" && setMode(Mode.ticketingFailed);
      }
      isSecondAccess.current = true;
    });

    //NOTE: データフェッチ
    setMode(Mode.fetch);
    audio[se.qr].play();
    const fetchData = await GetSpecificData("order", e.data);
    //NOTE: データが存在しない場合
    if (fetchData === null) {
      setErrMessage(ErrorMessages.notFound);
      setMode(Mode.notFound);
      audio[se.failed].play();
      isFirstAccess.current = true;
      return;
    }
    setOrder(fetchData);
    console.log(fetchData);

    //NOTE: サーマルプリンタにpost
    setMode(Mode.print);
    const menuData = fetchData?.menu.flatMap((e: any) => {
      return [e.title];
    }) as Array<string>;
    try {
      await publisher(e.data, menuData, "scanned");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Header />
      {mode === Mode.home && (
        <Home
          onClick={() => {
            prepareSound();
            setMode(Mode.reader);
          }}
        />
      )}
      {mode === Mode.reader && (
        <QRReader
          recognizeCallback={onRecognizeCode}
          width={500}
          height={500}
          cameraType={"user"}
          err={errMessage}
          pause={pause}
        />
      )}
      {mode === Mode.fetch && <Fetch />}
      {mode === Mode.print && order && <Print order={order} />}
      {(mode === Mode.notFound ||
        mode === Mode.ticketingFailed ||
        mode === Mode.complete) && <Complete mode={mode} />}

      {mode === Mode.admin && (
        <Admin
          onNext={() => {
            setMode(Mode.home);
          }}
        />
      )}
    </>
  );
}

export default App;
